import React from "react";
import { Link } from "react-router-dom";

import { toTitleCase } from "shared/helpers/string";
import appRoutes from "shared/constants/appRoutes";
import "./styles.scss";

export default function BreadCrumb({
  path,
  separator = "/",
  hideHome = false,
  wrapperClassName = "",
  crumbClassName = "",
  crumbSeparatorClassName = "",
  makeCrumbsUnreachableViaKeyNav = false
}: T_BreadCrumbProps) {
  // Generate no crumbs if path is home page
  const [, ...crumbs] =
    path === appRoutes.home.path ? [] : path.split(separator);

  return (
    <nav className={`Breadcrumb ${wrapperClassName}`} aria-label="Breadcrumb">
      <ol className="inline-flex flex-wrap items-center space-x-1 md:space-x-3">
        {!hideHome && (
          <li className="inline-flex items-center">
            <Link
              to={appRoutes.home.path}
              className={`crumbLink ${crumbClassName}`}
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
            </Link>
          </li>
        )}
        {crumbs.length > 0 &&
          crumbs.map((crumb, index) => {
            const [crumbLink] =
              path.match(
                // Given props.path "/abc/def/ghi"
                //   for 1-st iteration extract "/abc"
                //   for 2-nd iteration extract "/abc/def"
                //   and so on...
                new RegExp(`(${separator}[a-zA-Z0-9]+){${index + 1}}`)
              ) || [];

            return (
              <li key={`${crumb}${index}`} className="mt-0.5">
                <div className="flex">
                  {((index === 0 && !hideHome) || index > 0) && (
                    <svg
                      className={`w-6 h-6 text-gray-400 ${crumbSeparatorClassName}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  )}
                  <Link
                    to={crumbLink}
                    className={`crumbLink ${crumbClassName} mt-0.5`}
                    onClick={(e) => e.stopPropagation()}
                    tabIndex={makeCrumbsUnreachableViaKeyNav ? -1 : 0} // element is not reachable via sequential keyboard navigation, but could be focused with JavaScript or visually by clicking with the mouse
                  >
                    {toTitleCase(crumb)}
                  </Link>
                </div>
              </li>
            );
          })}
      </ol>
    </nav>
  );
}

interface T_BreadCrumbProps {
  path: string;
  separator?: string;
  hideHome?: boolean;
  wrapperClassName?: string;
  crumbClassName?: string;
  crumbSeparatorClassName?: string;
  /**
   * Crumb links need not be reachable via sequential keyboard navigation,
   * but could be focused with JavaScript or visually by mouse click
   */
  makeCrumbsUnreachableViaKeyNav?: boolean;
}
