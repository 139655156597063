/**
 * ======================
 * appRoutes - Guidelines
 * ======================
 *
 *  title:
 *    - Title of the page in Title Case
 *
 *  path:
 *    - Route path to render corresponding page
 *    - Each path fragment must be a single word
 *    - Each path fragment must be same as corresponding folder under pages/**
 *
 *  keywords:
 *    - List of related words for fuzzy search
 *    - Do not repeat words in title or path again in keywords array
 */

/**
 * Blogs
 */
const blogsTravelRoutes = {
  blogsTravel: {
    title: "Travel",
    path: "/blogs/travel",
    keywords: ["visit", "places"]
  },
  blogsTravelWorldMap: {
    title: "World Map",
    path: "/blogs/travel/worldmap",
    keywords: ["world", "countries", "visit", "places"]
  }
} as const;

const blogsRoutes = {
  blogs: {
    title: "Blogs",
    path: "/blogs",
    keywords: ["journal"]
  },
  blogsTechCharset: {
    title: "Charset",
    path: "/blogs/tech/charset",
    keywords: ["emoji", "emoticon", "markdown", "unicode", "special character"]
  },
  blogsTechCheatsheet: {
    title: "Cheatsheet",
    path: "/blogs/tech/cheatsheet",
    keywords: ["quick", "commands", "code", "basic"]
  },
  ...blogsTravelRoutes
} as const;

/**
 * Tools
 */
const toolsMoneyRoutes = {
  toolsMoney: {
    title: "Money",
    path: "/tools/money",
    keywords: [""]
  },
  toolsMoneyForex: {
    title: "Foreign Exchange",
    path: "/tools/money/forex",
    keywords: [
      "convert",
      "currency",
      // common currency names
      "rupee",
      "dollar",
      "euro",
      "pound",
      "yen",
      "yuan",
      "dong",
      "franc",
      "baht",
      // common currency codes
      "inr",
      "usd",
      "eud",
      "gbp",
      "nzd",
      "jpy"
    ]
  },
  toolsMoneyChit: {
    title: "Chit",
    description: "Chit Savings and Auction",
    path: "/tools/money/chit",
    keywords: ["chit", "auction", "bid", "sala", "installment"]
  }
} as const;

const toolsTimeRoutes = {
  toolsTime: {
    title: "Time",
    path: "/tools/time",
    keywords: ["timeZone", "date", "daylight", "saving"]
  },
  toolsTimeWorldClock: {
    title: "World Clock",
    path: "/tools/time/clock",
    keywords: ["timeZone", "world", "clock"]
  },
  toolsTimeTimeZone: {
    title: "Time Zone",
    description: "Compare Time Zones",
    path: "/tools/time/timezone",
    keywords: ["timeZone", "world", "clock", "compare", "convert"]
  }
} as const;

const toolsConvertRoutes = {
  toolsConvert: {
    title: "Unit Conversion",
    path: "/tools/convert",
    keywords: ["unit", "scales"]
  },
  toolsConvertTemp: {
    title: "Temperature Conversions",
    path: "/tools/convert/temp",
    keywords: ["cold", "hot", "kelvin", "celcius", "fahrenheit"]
  },
  toolsConvertLength: {
    title: "Length Conversions",
    path: "/tools/convert/length",
    keywords: ["meter", "mile", "kilometer", "feet", "inch", "centimeter"]
  }
} as const;

const toolsRoutes = {
  tools: {
    title: "Tools",
    path: "/tools",
    keywords: [""]
  },
  toolsCrypto: {
    title: "Cryptography",
    path: "/tools/crypto",
    keywords: ["encrypt", "decrypt", "aes"]
  },
  ...toolsConvertRoutes,
  ...toolsMoneyRoutes,
  ...toolsTimeRoutes
} as const;

const appRoutes = {
  home: {
    title: "Home",
    path: "/",
    keywords: ["top"]
  },
  ...blogsRoutes,
  ...toolsRoutes
} as const;

export default appRoutes;
