import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "appRoot/app";
import Home from "pages/home";
import appRoutes from "shared/constants/appRoutes";
import appRoutesToComponentsMap from "./helpers";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={appRoutes.home.path} element={<App />}>
          <Route index element={<Home />} />
          {appRoutesToComponentsMap.map((item) => (
            <Route key={item.path} path={item.path} element={<item.Component />} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes
