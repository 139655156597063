import {  } from "shared/helpers/dataStructure";
import { concat } from "shared/helpers/string";
import { scrollToPosition } from "shared/helpers/dom";
import { T_DocumentTitleParams } from "./types";

export const scrollToTop = () => scrollToPosition({ y: 0, behavior: "auto" });

export function getDocumentTitle(params: T_DocumentTitleParams) {
  return concat(
    [params.prefix, params.title, params.postfix],
    params.delimiter
  );
}
