import { useState, useEffect } from "react";
import appConstants from "shared/constants/app";
import {
  debounce as debounceFactory,
  throttle as throttleFactory
} from "shared/helpers/optimization";
import { T_Breakpoint } from "shared/types/window";
import { getActiveBreakpoint, getWindowDimensions } from "./helpers";
import { T_WindowDimensionListenerOptions } from "./types";

export function useWindowDimensions(
  options?: T_WindowDimensionListenerOptions
) {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    let handleResize = () => setWindowDimensions(getWindowDimensions());

    if (options?.throttle !== undefined && options.throttle > 0) {
      handleResize = throttleFactory(handleResize, options.throttle);
    }

    if (options?.debounce !== undefined && options.debounce > 0) {
      handleResize = debounceFactory(handleResize, options.debounce);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function useActiveBreakpoint(): T_Breakpoint {
  const { width } = useWindowDimensions({
    throttle: appConstants.responsive.activeBreakpointComputeInterval
  });
  const [activeBreakpoint, setActiveBreakpoint] = useState(
    getActiveBreakpoint(width)
  );

  useEffect(() => {
    const newActiveBreakpoint = getActiveBreakpoint(width);
    setActiveBreakpoint(newActiveBreakpoint);
  }, [width]);

  return activeBreakpoint;
}
