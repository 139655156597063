export enum E_KeyboardEventKey {
  down = "Down",
  arrowDown = "ArrowDown",
  up = "Up",
  arrowUp = "ArrowUp",
  left = "Left",
  arrowLeft = "ArrowLeft",
  right = "Right",
  arrowRight = "ArrowRight",
  enter = "Enter",
  esc = "Esc",
  escape = "Escape",
  delete = "Delete"
};
