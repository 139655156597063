export type T_URLString = string;

export type T_StringOrNumber = string | number;

export type T_StringOrNumberOrSymbol = T_StringOrNumber | symbol;

export enum E_JavaScriptLang {
  number = "number",
  string = "string",
  boolean = "boolean",
  object = "object",
  function = "function",
  undefined = "undefined",
  arrayNoMatchIndex = -1
}
