import { isNullOrVoid } from "./dataStructure";

export function getScrollPosition() {
  const doc = document.documentElement;
  const x = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
  const y = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

  return { x, y };
}

export function scrollToPosition({
  x,
  y,
  behavior = "smooth"
}: {
  x?: number;
  y?: number;
  behavior?: ScrollBehavior;
}) {
  const options: ScrollToOptions = { behavior };
  if (!isNullOrVoid(x)) options.left = x;
  if (!isNullOrVoid(y)) options.top = y;

  window.scrollTo(options);
}

export function isTouchEvent<T>(event: any): event is React.TouchEvent<T> {
  return event?.hasOwnProperty("targetTouches");
}

export function isKeyboardEvent<T>(event: any): event is React.TouchEvent<T> {
  return event?.hasOwnProperty("key");
}

export function isChangeEvent<T>(event: any): event is React.ChangeEvent<T> {
  return event?.target?.hasOwnProperty("value");
}
