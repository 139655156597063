export const breakpoints = {
  none: {
    name: "none",
    value: 0
  },
  doubleExtraSmall: {
    name: "2xs",
    value: 200
  },
  extraSmall: {
    name: "xs",
    value: 300
  },
  small: {
    name: "sm",
    value: 640
  },
  medium: {
    name: "md",
    value: 768
  },
  large: {
    name: "lg",
    value: 1024
  },
  extraLarge: {
    name: "xl",
    value: 1280
  },
  doubleExtraLarge: {
    name: "2xl",
    value: 1536
  },
  tripleExtraLarge: {
    name: "3xl",
    value: 1700
  }
} as const;

const windowConstants = {
  breakpoints
} as const;

export default windowConstants;
