import { RefObject, useEffect, useState } from "react";

import { E_KeyboardEventKey } from "shared/types/browserEvent";

export function useArrowKeysToFocusElement<T extends HTMLElement>(
  elements: RefObject<T>[],
  options?: Partial<{
    noOfElementsPerRow: number;
    initActiveElementIndex: number;
    isUpAndDownOnly: boolean;
    isLeftAndRightOnly: boolean;
    isKeyListenerDisabled: boolean;
  }>
) {
  const {
    noOfElementsPerRow = 1,
    initActiveElementIndex = 0,
    isUpAndDownOnly = false,
    isLeftAndRightOnly = false,
    isKeyListenerDisabled = false
  } = options || {};
  const [activeElementIndex, setActiveElementIndex] = useState(
    initActiveElementIndex ?? -1
  );
  const isArrowUpPressed = useIsKeyPressed(
    E_KeyboardEventKey.arrowUp,
    isKeyListenerDisabled || isLeftAndRightOnly
  );
  const isArrowDownPressed = useIsKeyPressed(
    E_KeyboardEventKey.arrowDown,
    isKeyListenerDisabled || isLeftAndRightOnly
  );
  const isArrowRightPressed = useIsKeyPressed(
    E_KeyboardEventKey.arrowRight,
    isKeyListenerDisabled || isUpAndDownOnly
  );
  const isArrowLeftPressed = useIsKeyPressed(
    E_KeyboardEventKey.arrowLeft,
    isKeyListenerDisabled || isUpAndDownOnly
  );

  useEffect(() => {
    let isSomeKeyPressed = false;
    let isMultipleKeysPressed = false;
    [
      isArrowUpPressed,
      isArrowDownPressed,
      isArrowLeftPressed,
      isArrowRightPressed
    ].forEach((isKeyPressed) => {
      if (isKeyPressed) {
        if (isSomeKeyPressed) {
          isMultipleKeysPressed = true;
        }
        isSomeKeyPressed = true;
      }
    });

    if (isSomeKeyPressed && !isMultipleKeysPressed) {
      let newActiveElementIndex = activeElementIndex;

      if (isArrowUpPressed) {
        newActiveElementIndex = activeElementIndex - noOfElementsPerRow;
      }
      if (isArrowDownPressed) {
        newActiveElementIndex = activeElementIndex + noOfElementsPerRow;
      }
      if (isArrowRightPressed) {
        newActiveElementIndex = activeElementIndex + 1;
      }
      if (isArrowLeftPressed) {
        newActiveElementIndex = activeElementIndex - 1;
      }
      if (newActiveElementIndex < 0) {
        newActiveElementIndex = 0;
      }

      setActiveElementIndex(newActiveElementIndex);
      elements[newActiveElementIndex]?.current?.focus();
    }
    // Intentionally omitted 'activeElementIndex' from dependency array
    // as focusing logic need not re-run based on 'activeElementIndex' update,
    // but only when keys are pressed or elements are changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isArrowUpPressed,
    isArrowDownPressed,
    isArrowLeftPressed,
    isArrowRightPressed,
    elements,
    noOfElementsPerRow
  ]);

  return { setActiveElementIndex };
}

export function useIsKeyPressed(
  targetKey: E_KeyboardEventKey,
  isKeyListenerDisabled = false
) {
  // State for keeping track of whether key is pressed
  const [isKeyPressed, setIsKeyPressed] = useState<boolean>(false);

  // Add event listeners
  useEffect(() => {
    // If pressed key is our target key then set to true
    function downHandler(event: KeyboardEvent) {
      if (!isKeyListenerDisabled && event.key === targetKey) {
        // event.preventDefault();
        setIsKeyPressed(true);
      }
    }
    // If released key is our target key then set to false
    function upHandler(event: KeyboardEvent) {
      if (!isKeyListenerDisabled && event.key === targetKey) {
        // event.preventDefault();
        setIsKeyPressed(false);
      }
    }
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [isKeyListenerDisabled, targetKey]);

  return isKeyPressed;
}
