import React, { lazy, Suspense } from "react";

import ErrorBoundary from "../errorBoundary";
import Loading from "../loading";

export default function lazyComponent(srcImportPath: string) {
  const Component = lazy(() => import(`../../../${srcImportPath}`));

  return function () {
    return (
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Component />
        </Suspense>
      </ErrorBoundary>
    );
  };
}
