import Fuse from "fuse.js";

import appRoutes from "shared/constants/appRoutes";

const [, ...siteSearchList] = Object.values(appRoutes);

// @see https://fusejs.io/api/options.html
const fuzzySearchOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 1,
  // location: 0,
  // threshold: 0.6,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: [{ name: "title", weight: 3 }, { name: "path", weight: 2 }, "keywords"]
};

// @see https://fusejs.io/api/indexing.html
const siteIndex = Fuse.createIndex(fuzzySearchOptions.keys, siteSearchList);

// initialize Fuse with the index
export const fuzzySearch = new Fuse(
  siteSearchList,
  fuzzySearchOptions,
  siteIndex
);
