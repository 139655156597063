import React from "react";
import * as ReactDOM from "react-dom/client";
import "./appRoot/index.css";
import AppRoutes from "appRoot/appRoutes";
import reportWebVitals from "./appRoot/reportWebVitals";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppRoutes />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
