export interface T_KeyboarKeyProps {
  keyboardKey: E_KeyboardKey;
  className?: string;
};

export const enum E_KeyboardKey {
  upArrow = "▲",
  downArrow = "▼",
  leftArrow = "◀",
  rightArrow = "▶",
  delete = "Del",
  enter = "Enter",
};
