import { Outlet, useLocation } from "react-router-dom";

import appRoutes from "shared/constants/appRoutes";
import Breadcrumb from "shared/components/breadcrumb";
import { useDocumentTitle, useScrollToTop } from "./hooks";
import { useActiveBreakpoint } from "shared/hooks/window";
import { getLastPartInTitleCase } from "shared/helpers/string";
import { isDev } from "shared/helpers/env";

function App() {
  const location = useLocation();
  const { scrollToTop, hasSomeScroll } = useScrollToTop();
  const activeBreakpoint = useActiveBreakpoint();
  useDocumentTitle({
    postfix: "yadhu.me",
    title: getLastPartInTitleCase(location.pathname, "/"),
    delimiter: " :: ",
    prefix: isDev() ? activeBreakpoint.name : ""
  });

  return (
    <>
      {location.pathname !== appRoutes.home.path && (
        <Breadcrumb path={location.pathname} />
      )}
      <button
        x-data="topBtn"
        id="topButton"
        className={`${
          hasSomeScroll ? "" : "hidden"
        } fixed z-10 p-1 rounded-l-xl shadow-2xl bottom-5 right-0 border-l-2 border-neutral-300 bg-neutral-300 bg-opacity-30 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg flex h-28`}
        onClick={scrollToTop}
      >
        <svg
          className="w-4 h-4 my-1.5 animate-bounce"
          fill="none"
          stroke="black"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.5"
            d="M5 10l7-7m0 0l7 7m-7-7v18"
          ></path>
        </svg>
        <span className="text-xs text-slate-500 absolute -rotate-90 bottom-9 font-mono  -left-7 w-20">Go to top</span>
      </button>
      {/* Outlet for child routes */}
      <Outlet />
    </>
  );
}

export default App;
