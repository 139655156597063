import React from "react";
import { Link } from "react-router-dom";
import appRoutes from "shared/constants/appRoutes";

export default class ErrorBoundary extends React.Component<{
  children: React.ReactNode;
}> {
  state = {
    hasError: false,
    errorMsg: ""
  };

  static getDerivedStateFromError(error: any) {
    let errorMsg = "Something went wrong.";
    // Update state so the next render will show the fallback UI.
    if (error.name === "ChunkLoadError") {
      errorMsg = "Unable to load the page.";
    }

    return { hasError: true, errorMsg };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error.message, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>
            {this.state.errorMsg} Please go{" "}
            <Link to={appRoutes.home.path}>
              <strong>home.</strong>
            </Link>
          </h1>
        </>
      );
    }

    return this.props.children;
  }
}
